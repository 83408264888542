import React from 'react';
import { graphql } from 'gatsby';
import { Image, ImageProps } from '../atoms/image';
import { AlignedMarkdown } from '../atoms/aligned-markdown';

import styled from '@emotion/styled';

export type MarkdownWithImageProps = {
  markdown?: string;
  image?: ImageProps;
};

const Wrapper = styled.div`
  margin-bottom: 50px;
`;

const StyledMarkdown = styled(AlignedMarkdown)`
  margin-bottom: 20px;
`;

export const MarkdownWithImage: React.FC<MarkdownWithImageProps> = props => {
  return (
    <Wrapper>
      <StyledMarkdown markdown={props.markdown} align="left" />
      <Image {...props.image} />
    </Wrapper>
  );
};

MarkdownWithImage.defaultProps = {
  markdown: '',
};

export const MarkdownWithImageFragment = graphql`
  fragment MarkdownWithImage on cms_ComponentHowWeWorkWithOurPartnersImageWithText {
    text
    image {
      ...CommonMediaField
      staticFile {
        childImageSharp {
          gatsbyImageData(width: 400, placeholder: BLURRED, layout: CONSTRAINED)
        }
      }
    }
  }
`;
