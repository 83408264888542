import React from 'react';
import { graphql } from 'gatsby';
import { useIntl } from 'gatsby-plugin-react-intl';
import { AvailableLocales, parseLocale } from '../locale';
import SEO from '../components/seo';
import { HowWeWorkWithOurPartnersTemplate } from '../templates/how-we-work-with-our-partners.template';
import {
  HowWeWorkWithOurPartnersQuery,
  MarkdownWithTagsFragment,
  NumberedListFragment,
  ScrollableColumnsFragment,
  TriptychFragment,
  AccordionWithImageSectionFragment,
} from '../../graphql-types';
import { safeApply, safeMap } from '../data-mappers/utils';
import {
  mapFragmentToAccordionWithImage,
  mapFragmentToMarkdownWithTagsProps,
  mapFragmentToNumberedListProps,
  mapFragmentToScrollableColumnsProps,
} from '../data-mappers/how-we-work';
import { mapFragmentToTriptychProps } from '../data-mappers/triptych.mapper';
import { HowWeWorkSections } from '../templates/how-we-work-with-our-partners.template';
import { mapFragmentToAlignedMarkdownProps } from '../data-mappers/markdown.mapper';
import { mapFragmentToImageProps } from '../data-mappers/image.mapper';
import { HowWeWorkSubMenuItemProps } from '../components/molecules/how-we-work-sub-menu';
import GithubSlugger from 'github-slugger';
import { useBreadcrumbs } from '../hooks/useBreadcrumbs';
import { useLocalizedSlugs } from '../hooks/useLocalizedSlugs';

export const query = graphql`
  query HowWeWorkWithOurPartners($language: String) {
    cms {
      howWeWorkWithOurPartner(locale: $language) {
        metas {
          id
          description
        }
        title
        introduction {
          ...AlignedMarkdown
        }
        image {
          ...CommonMediaField
          staticFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
            }
          }
        }
        image_mobile {
          ...CommonMediaField
          staticFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
            }
          }
        }
        sections {
          ...MarkdownWithTags
          ...Triptych
          ...NumberedList
          ...ScrollableColumns
          ...AccordionWithImageSection
        }
      }
    }
  }
`;

const HowWeWorkWithOurPartners: React.FC<{ data: HowWeWorkWithOurPartnersQuery }> = ({ data }) => {
  const { cms } = data;

  const intl = useIntl();
  const locale = parseLocale(intl.locale);

  const props = {
    title: cms.howWeWorkWithOurPartner?.title ?? '',
    menu: safeMap(fragment => mapFragmentToMenuItemProps(fragment), cms.howWeWorkWithOurPartner?.sections),
    introduction: safeApply(
      value => mapFragmentToAlignedMarkdownProps(value),
      cms.howWeWorkWithOurPartner?.introduction
    ),
    image: mapFragmentToImageProps(cms.howWeWorkWithOurPartner?.image, locale),
    sections: safeMap(
      fragment => mapFragmentToHowWeWorkSection(fragment, locale),
      cms.howWeWorkWithOurPartner?.sections
    ),
  };

  useBreadcrumbs({
    pageTitle: cms.howWeWorkWithOurPartner?.title ?? '',
    contentCategory: intl.formatMessage({ id: 'kering_foundation' }),
  });

  useLocalizedSlugs({
    slugs: [
      { locale: 'en', slug: 'how-we-work-with-our-partners' },
      { locale: 'fr', slug: 'comment-travaillons-nous-avec-nos-partenaires' },
    ],
  });

  return (
    <>
      <SEO lang={locale} title={props.title} />
      <HowWeWorkWithOurPartnersTemplate {...props} />
    </>
  );
};

function mapFragmentToMenuItemProps(fragment: SectionFragment): HowWeWorkSubMenuItemProps {
  const slugger = new GithubSlugger();

  return {
    label: fragment.menu_title ?? '',
    anchor: slugger.slug(fragment.section_title ?? ''),
  };
}

type SectionFragment =
  | MarkdownWithTagsFragment
  | NumberedListFragment
  | ScrollableColumnsFragment
  | TriptychFragment
  | AccordionWithImageSectionFragment;

function mapFragmentToHowWeWorkSection(fragment: SectionFragment, locale: AvailableLocales): HowWeWorkSections {
  switch (fragment.__typename) {
    case 'cms_ComponentHowWeWorkWithOurPartnersTextWithTags':
      return { type: 'textWithTags', props: mapFragmentToMarkdownWithTagsProps(fragment) };

    case 'cms_ComponentHowWeWorkWithOurPartnersNumberedList':
      return { type: 'numberOrderedList', props: mapFragmentToNumberedListProps(fragment) };

    case 'cms_ComponentHowWeWorkWithOurPartnersScrollableColumns':
      return { type: 'scrollableColumns', props: mapFragmentToScrollableColumnsProps(fragment, locale) };

    case 'cms_ComponentHowWeWorkWithOurPartnersTriptych':
      return { type: 'imagesWithTextTriptych', props: mapFragmentToTriptychProps(fragment, locale) };

    case 'cms_ComponentHowWeWorkWithOurPartnersImageWithAccordion':
      return { type: 'imageWithAccordion', props: mapFragmentToAccordionWithImage(fragment, locale) };
  }
}

export default HowWeWorkWithOurPartners;
