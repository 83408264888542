import { TriptychFragment } from '../../graphql-types';
import { TriptychProps } from '../components/organisms/triptych';
import { AvailableLocales } from '../locale';
import { mapFragmentToImageProps } from './image.mapper';
import { safeMap } from './utils';

export function mapFragmentToTriptychProps(fragment: TriptychFragment, locale: AvailableLocales): TriptychProps {
  return {
    title: fragment.section_title ?? '',
    cards: safeMap(
      card => ({
        id: card.id,
        text: card.text ?? '',
        image: mapFragmentToImageProps(card.image, locale),
      }),
      fragment.image_with_text
    ),
  };
}
