import React from 'react';
import { graphql } from 'gatsby';
import { TagList } from './tag-list';
import { AlignedMarkdown, AlignedMarkdownProps } from '../atoms/aligned-markdown';
import { Heading } from '../atoms/heading';
import { VerticalSeparator } from '../atoms/vertical-separator';

import styled from '@emotion/styled';

export type MarkdownWithTagsProps = {
  sectionTitle?: string;
  sectionText?: AlignedMarkdownProps;
  tags?: string[];
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: 60px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoint.desktop}) {
    padding: 0 20px;
  }
`;

export const MarkdownWithTags: React.FC<MarkdownWithTagsProps> = props => {
  return (
    <Wrapper>
      <Heading level="h2" text={props.sectionTitle} />
      <VerticalSeparator marginTop={20} marginBottom={40} />
      <AlignedMarkdown {...props.sectionText} align="center" maxWidth="body_text" />
      <TagList tags={props.tags} />
    </Wrapper>
  );
};

MarkdownWithTags.defaultProps = {
  sectionTitle: '',
  sectionText: {
    markdown: '',
  },
  tags: [],
};

export const MarkdownWithTagsFragment = graphql`
  fragment MarkdownWithTags on cms_ComponentHowWeWorkWithOurPartnersTextWithTags {
    __typename
    section_title
    menu_title
    tags {
      ...Tag
    }
    section_text {
      ...AlignedMarkdown
    }
  }
`;
