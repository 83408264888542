import React, { useState } from 'react';
import styled from '@emotion/styled';
import InView from 'react-intersection-observer';
import { math, rem, remToPx } from 'polished';

import { Section } from '../components/molecules/section';
import { HeaderSection } from '../components/molecules/header-section';
import { MarkdownWithTags, MarkdownWithTagsProps } from '../components/molecules/markdown-with-tags';
import { Triptych, TriptychProps } from '../components/organisms/triptych';
import { NumberedList, NumberedListProps } from '../components/molecules/numbered-list';
import { ScrollableColumns, ScrollableColumnsProps } from '../components/molecules/scrollable-columns';
import { AccordionWithImage, AccordionWithImageProps } from '../components/molecules/accordion-with-image';
import { AlignedMarkdown, AlignedMarkdownProps } from '../components/atoms/aligned-markdown';
import { Image, ImageProps } from '../components/atoms/image';
import { VerticalSeparator } from '../components/atoms/vertical-separator';
import { HowWeWorkSubMenu, HowWeWorkSubMenuProps } from '../components/molecules/how-we-work-sub-menu';
import { useBreakpoints } from '../hooks/useBreakpoints';
import { theme } from '../theme';

export type HowWeWorkSections =
  | { type: 'textWithTags'; props: MarkdownWithTagsProps }
  | { type: 'imagesWithTextTriptych'; props: TriptychProps }
  | { type: 'numberOrderedList'; props: NumberedListProps }
  | { type: 'scrollableColumns'; props: ScrollableColumnsProps }
  | { type: 'imageWithAccordion'; props: AccordionWithImageProps };

export type HowWeWorkTemplateProps = {
  title: string;
  introduction?: AlignedMarkdownProps;
  image?: ImageProps;
  menu: HowWeWorkSubMenuProps['subMenuItems'];
  sections: HowWeWorkSections[];
};

const HowWeWorkWrapper = styled.div`
  & h1,
  & h2,
  & h3,
  & h4 {
    scroll-margin-top: calc(
      20px +
        ${({ theme }) =>
          [theme.spacing.breadcrumbs, theme.spacing.headerHeight, theme.spacing.howWeWorkSubMenu].join(' + ')}
    );
  }
`;

export const HowWeWorkWithOurPartnersTemplate: React.FC<HowWeWorkTemplateProps> = props => {
  const { isDesktop } = useBreakpoints();
  const [activeMenu, updateVisibility] = useFirstVisibleItem(props.menu);

  const { breadcrumbs, headerHeight, howWeWorkSubMenu } = theme.spacing;
  // Negative margin is applied to observer to sync visibility update with what user really can see
  const rootTopMargin = '-' + remToPx(math([rem('20px'), breadcrumbs, headerHeight, howWeWorkSubMenu].join('+')));

  return (
    <HowWeWorkWrapper>
      <HeaderSection>
        {props.introduction && <AlignedMarkdown {...props.introduction} maxWidth="body_text" />}
      </HeaderSection>

      <Section marginPreset={isDesktop ? 'bottom' : 'none'}>
        <Image {...props.image} />
      </Section>

      <HowWeWorkSubMenu subMenuItems={props.menu} activeMenuItem={activeMenu} />

      {props.sections.map((section, index) => {
        return (
          <>
            {index !== 0 && <VerticalSeparator />}

            <InView
              as="div"
              rootMargin={`${rootTopMargin} 0px 0px 0px`}
              onChange={inView => updateVisibility(inView, index)}
            >
              {mapSectionToElement(section)}
            </InView>
          </>
        );
      })}
    </HowWeWorkWrapper>
  );
};

function useFirstVisibleItem<T>(menu: T[]): [T | undefined, (isVisible: boolean, index: number) => void] {
  const [visibilityList, setVisibilityList] = useState(menu.map(() => false));
  const firstVisibleItem = menu.find((_, index) => visibilityList[index]);

  const updateVisibility = (isVisible: boolean, index: number) => {
    setVisibilityList(state => {
      return [...state.slice(0, index), isVisible, ...state.slice(index + 1)];
    });
  };

  return [firstVisibleItem, updateVisibility];
}

function mapSectionToElement(section: HowWeWorkSections): JSX.Element {
  switch (section.type) {
    case 'textWithTags':
      return (
        <Section marginPreset="sides">
          <MarkdownWithTags {...section.props} />
        </Section>
      );

    case 'imagesWithTextTriptych':
      return (
        <Section marginPreset="sides">
          <Triptych {...section.props} />
        </Section>
      );

    case 'numberOrderedList':
      return (
        <Section marginPreset="sides">
          <NumberedList {...section.props} />
        </Section>
      );

    case 'scrollableColumns':
      return (
        <Section marginPreset="none">
          <ScrollableColumns {...section.props} />
        </Section>
      );

    case 'imageWithAccordion':
      return (
        <Section marginPreset="none">
          <AccordionWithImage {...section.props} />
        </Section>
      );
  }
}
