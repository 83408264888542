import React from 'react';
import styled from '@emotion/styled';
import { graphql } from 'gatsby';
import { Heading } from '../atoms/heading';
import { Image, ImageProps } from '../atoms/image';
import { AlignedMarkdown } from '../atoms/aligned-markdown';

type TriptychCard = {
  id: string;
  image?: ImageProps;
  text?: string;
};

export type TriptychProps = {
  title: string;
  cards: TriptychCard[];
};

const Cards = styled.div`
  @media screen and (min-width: ${({ theme }) => theme.breakpoint.desktop}) {
    display: flex;
    flex-direction: row;
    margin: 0 -70px;
  }
`;

const Card = styled.div`
  margin-bottom: 50px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoint.desktop}) {
    flex: 1 1 0;
    margin: 0 70px;
  }
`;

export const Triptych: React.FC<TriptychProps> = props => {
  return (
    <>
      <Heading level="h2" text={props.title} css={{ textAlign: 'center', marginBottom: '50px' }} />

      <Cards>
        {props.cards.map(card => (
          <Card key={card.id}>
            <Image {...card.image} css={{ marginBottom: '30px' }} />
            <AlignedMarkdown align="left" markdown={card.text} />
          </Card>
        ))}
      </Cards>
    </>
  );
};

export const triptychFragment = graphql`
  fragment Triptych on cms_ComponentHowWeWorkWithOurPartnersTriptych {
    __typename
    menu_title
    section_title
    image_with_text {
      ...ImageWithText
    }
  }

  fragment ImageWithText on cms_ComponentHowWeWorkWithOurPartnersImageWithText {
    id
    text
    image {
      ...CommonMediaField
      staticFile {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
        }
      }
    }
  }
`;
