import React from 'react';
import styled from '@emotion/styled';
import { useBreakpoints } from '../../hooks/useBreakpoints';

export type HowWeWorkSubMenuProps = {
  subMenuItems: HowWeWorkSubMenuItemProps[];
  activeMenuItem?: HowWeWorkSubMenuItemProps;
};

export type HowWeWorkSubMenuItemProps = {
  label: string;
  anchor: string;
};

type ListItemProps = {
  isActive: boolean;
};

const Wrapper = styled.nav`
  position: sticky;
  top: calc(${({ theme }) => theme.spacing.headerHeight} + ${({ theme }) => theme.spacing.breadcrumbs});
  background-color: white;
  z-index: ${({ theme }) => theme.zindex.submenu};

  height: ${({ theme }) => theme.spacing.howWeWorkSubMenu};

  border-top: 1px solid ${({ theme }) => theme.color.palette['gray-lighten-1']};
  border-bottom: 1px solid ${({ theme }) => theme.color.palette['gray-lighten-1']};
`;

const List = styled.ul`
  margin: 0;
  padding: 0;

  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  list-style-type: none;

  height: 100%;
`;

const ListItem = styled.li<ListItemProps>`
  a {
    font-size: ${({ theme }) => theme.font.size.default};
    font-family: ${({ theme }) => theme.font.family.header};
    color: ${({ isActive, theme }) =>
      isActive ? theme.color.palette['base-blue'] : theme.color.palette['pure-black']};

    &:hover {
      color: ${({ theme }) => theme.color.primary};
    }
  }
`;

export const HowWeWorkSubMenu: React.FC<HowWeWorkSubMenuProps> = props => {
  const { isDesktop } = useBreakpoints();

  if (!isDesktop) {
    return null;
  }

  return (
    <Wrapper>
      <List>
        {props.subMenuItems.map(item => (
          <ListItem key={item.anchor} isActive={item === props.activeMenuItem}>
            <a href={`#${item.anchor}`}>{item.label}</a>
          </ListItem>
        ))}
      </List>
    </Wrapper>
  );
};
