import React from 'react';

import {
  ScrollableColumnsFragment,
  MarkdownWithImageFragment,
  MarkdownWithTagsFragment,
  NumberedListFragment,
  AccordionWithImageSectionFragment,
} from '../../graphql-types';
import { NumberedListProps } from '../components/molecules/numbered-list';
import { MarkdownWithImageProps } from '../components/molecules/markdown-with-image';
import { MarkdownWithTagsProps } from '../components/molecules/markdown-with-tags';
import { ScrollableColumnsProps } from '../components/molecules/scrollable-columns';
import { AvailableLocales } from '../locale';
import { mapFragmentToAlignedMarkdownProps } from './markdown.mapper';
import { mapFragmentToImageProps } from './image.mapper';
import { mapFragmentToTagList } from './homepage.mapper';
import { safeApply, safeMap } from './utils';
import { AccordionItem } from '../components/molecules/accordion';
import { AccordionWithImageProps } from '../components/molecules/accordion-with-image';
import { AlignedMarkdown } from '../components/atoms/aligned-markdown';

export const mapFragmentToMarkdownWithImageProps = (
  fragment: MarkdownWithImageFragment,
  locale: AvailableLocales
): MarkdownWithImageProps => {
  return {
    markdown: fragment.text ?? '',
    image: mapFragmentToImageProps(fragment.image, locale),
  };
};

export const mapFragmentToScrollableColumnsProps = (
  fragment: ScrollableColumnsFragment,
  locale: AvailableLocales
): ScrollableColumnsProps => {
  return {
    markdown: fragment.section_simple_text ?? '',
    sectionTitle: fragment.section_title ?? '',
    scrollableList: safeMap(
      imageWithText => mapFragmentToMarkdownWithImageProps(imageWithText, locale),
      fragment.image_with_text
    ),
  };
};

export const mapFragmentToMarkdownWithTagsProps = (fragment: MarkdownWithTagsFragment): MarkdownWithTagsProps => {
  return {
    sectionTitle: fragment.section_title ?? '',
    sectionText: safeApply(value => mapFragmentToAlignedMarkdownProps(value), fragment.section_text),
    tags: mapFragmentToTagList(fragment.tags),
  };
};

export const mapFragmentToNumberedListProps = (fragment: NumberedListFragment): NumberedListProps => {
  return {
    sectionTitle: fragment.section_title ?? '',
    sectionText: safeApply(val => mapFragmentToAlignedMarkdownProps(val), fragment.section_text),
    steps: safeMap(step => mapFragmentToAlignedMarkdownProps(step), fragment.steps),
  };
};

export const mapFragmentToAccordionWithImage = (
  fragment: AccordionWithImageSectionFragment,
  locale: AvailableLocales
): AccordionWithImageProps => {
  return {
    title: fragment.section_title ?? '',
    markdown: safeApply(value => mapFragmentToAlignedMarkdownProps(value), fragment.section_text),
    image: mapFragmentToImageProps(fragment.image, locale),
    accordion: {
      children: safeMap(
        item => (
          <AccordionItem key={item.title} title={item.title ?? ''}>
            {safeApply(
              text => (
                <AlignedMarkdown css={{ marginTop: '20px' }} align="left" markdown={text} />
              ),
              item.text
            )}
          </AccordionItem>
        ),
        fragment.accordion_item
      ),
    },
  };
};
