import React from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';

import { MarkdownWithImage, MarkdownWithImageProps } from './markdown-with-image';
import { AlignedMarkdown } from '../atoms/aligned-markdown';
import { Heading } from '../atoms/heading';
import { useBreakpoints } from '../../hooks/useBreakpoints';
import { ScrollHint } from '../atoms/scroll-hint';

export type ScrollableColumnsProps = {
  sectionTitle?: string;
  markdown?: string;
  scrollableList?: MarkdownWithImageProps[];
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  background: ${({ theme }) => theme.color.palette['gray-lighten-3']};

  @media screen and (max-width: ${({ theme }) => theme.breakpoint.desktop}) {
    flex-direction: column;
    padding: 0 20px;
    background: ${({ theme }) => theme.color.palette['pure-white']};
  }
`;

const MarkdownWrapper = styled.div`
  display: flex;
  flex: 1;
`;

const MarkdownContent = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (min-width: ${({ theme }) => theme.breakpoint.desktop}) {
    padding: 70px ${({ theme }) => theme.spacing.body} 0 ${({ theme }) => theme.spacing.body};
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoint.desktop}) {
    padding-top: 70px;
    margin-bottom: 50px;
  }
`;

const ScrollableList = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background: ${({ theme }) => theme.color.palette['pure-white']};

  @media screen and (min-width: ${({ theme }) => theme.breakpoint.desktop}) {
    padding: 70px ${({ theme }) => theme.spacing.body} 50px ${({ theme }) => theme.spacing.body};
    max-height: 550px;
    overflow-y: auto;

    scrollbar-width: none;

    ::-webkit-scrollbar {
      width: 0px;
      background: transparent;
    }
  }
`;

const Scrollable = styled.div`
  display: block;
  flex: 1;
`;

export const ScrollableColumns: React.FC<ScrollableColumnsProps> = props => {
  const { isDesktop } = useBreakpoints();
  const align = isDesktop ? 'left' : 'center';

  return (
    <Wrapper>
      <MarkdownWrapper>
        <MarkdownContent style={{ textAlign: align }}>
          <Heading level="h2" text={props.sectionTitle} />
          <AlignedMarkdown markdown={props.markdown} align={align} />
        </MarkdownContent>
      </MarkdownWrapper>
      <Scrollable>
        <ScrollableList>
          {props.scrollableList?.map(itemProps => {
            return <MarkdownWithImage key={itemProps?.image?.alt || itemProps?.image?.title} {...itemProps} />;
          })}
        </ScrollableList>

        <ScrollHint css={{ height: '80px', marginTop: '-80px', position: 'relative' }} />
      </Scrollable>
    </Wrapper>
  );
};

ScrollableColumns.defaultProps = {
  sectionTitle: '',
  markdown: '',
  scrollableList: [],
};

export const ScrollableColumnsFragment = graphql`
  fragment ScrollableColumns on cms_ComponentHowWeWorkWithOurPartnersScrollableColumns {
    __typename
    section_title
    menu_title
    section_simple_text: section_text
    image_with_text {
      ...MarkdownWithImage
    }
  }
`;
