import React from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';

import { AlignedMarkdown, AlignedMarkdownProps } from '../atoms/aligned-markdown';
import { Heading } from '../atoms/heading';
import { useBreakpoints } from '../../hooks/useBreakpoints';

export type NumberedListProps = {
  sectionTitle: string;
  sectionText?: AlignedMarkdownProps;
  steps?: AlignedMarkdownProps[];
};

const Wrapper = styled.div`
  @media screen and (min-width: ${({ theme }) => theme.breakpoint.desktop}) {
    display: grid;
    grid-gap: 100px;
    grid-template-columns: 1fr 1fr;
  }
`;

const TitleWrapper = styled.div``;

const ListWrapper = styled.div`
  text-align: center;

  @media screen and (min-width: ${({ theme }) => theme.breakpoint.desktop}) {
    text-align: left;
  }
`;

const Steps = styled.div`
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    padding-bottom: 20px;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoint.desktop}) {
    flex-direction: row;

    &:not(:last-child) {
      padding-bottom: 40px;
    }
  }
`;

const StepNumber = styled.div`
  font-family: ${({ theme }) => theme.font.family.header};
  color: ${({ theme }) => theme.color.palette['base-blue']};
  font-size: ${({ theme }) => theme.font.size.h1};
  line-height: ${({ theme }) => theme.font.lineHeight.h1};

  @media screen and (min-width: ${({ theme }) => theme.breakpoint.desktop}) {
    margin-right: 30px;
  }
`;

const MarkdownWrapper = styled.div`
  margin-top: calc((${({ theme }) => theme.font.size.h1} - ${({ theme }) => theme.font.size.h3}) / 2);
`;

export const NumberedList: React.FC<NumberedListProps> = props => {
  const { isDesktop } = useBreakpoints();
  const titleAlign = isDesktop ? props.sectionText?.align : 'center';

  return (
    <Wrapper>
      <TitleWrapper>
        <Heading level="h2" text={props.sectionTitle} />

        {props.sectionText && <AlignedMarkdown align={titleAlign} markdown={props.sectionText.markdown} />}
      </TitleWrapper>
      <ListWrapper>
        {props.steps?.map((step, index) => {
          const stepAlign = isDesktop ? step.align : 'center';
          const stepNumber = `0${index + 1}`;
          return (
            <Steps key={index}>
              <StepNumber>{stepNumber}</StepNumber>
              <MarkdownWrapper>
                <AlignedMarkdown align={stepAlign} markdown={step.markdown} />
              </MarkdownWrapper>
            </Steps>
          );
        })}
      </ListWrapper>
    </Wrapper>
  );
};

export const NumberedListFragment = graphql`
  fragment NumberedList on cms_ComponentHowWeWorkWithOurPartnersNumberedList {
    __typename
    section_title
    menu_title
    section_text {
      ...AlignedMarkdown
    }
    steps {
      ...AlignedMarkdown
    }
  }
`;
