import React from 'react';
import { Image, ImageProps } from '../atoms/image';
import { AlignedMarkdown, AlignedMarkdownProps } from '../atoms/aligned-markdown';
import styled from '@emotion/styled';
import { Accordion, AccordionProps } from './accordion';
import { graphql } from 'gatsby';
import { Heading } from '../atoms/heading';

export type AccordionWithImageProps = {
  title: string;
  markdown?: AlignedMarkdownProps;
  image?: ImageProps;
  accordion: AccordionProps;
};

const AccordionWithImageTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  max-width: 500px;
  text-align: center;
  margin-bottom: 50px;
`;

const AccordionWithImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const AccordionWithImageContent = styled.div`
  flex: 1;

  @media screen and (min-width: ${({ theme }) => theme.breakpoint.desktop}) {
    display: grid;
    grid-gap: 100px;
    grid-template-columns: 1fr 1fr;
    align-items: center;
  }
`;

const AccordionWrapper = styled.div`
  margin: 40px ${({ theme }) => theme.spacing.bodyMobile} 0;

  @media screen and (min-width: ${({ theme }) => theme.breakpoint.desktop}) {
    margin: 0;
  }
`;

export const AccordionWithImage: React.FC<AccordionWithImageProps> = props => {
  return (
    <AccordionWithImageWrapper>
      <AccordionWithImageTitle>
        <Heading level="h2" text={props.title} noMargin />
        <AlignedMarkdown {...props.markdown} align="center" />
      </AccordionWithImageTitle>
      <AccordionWithImageContent>
        <Image {...props.image} />

        <AccordionWrapper>
          <Accordion {...props.accordion} overflow="visible" />
        </AccordionWrapper>
      </AccordionWithImageContent>
    </AccordionWithImageWrapper>
  );
};

export const fragment = graphql`
  fragment AccordionWithImageSection on cms_ComponentHowWeWorkWithOurPartnersImageWithAccordion {
    __typename
    section_title
    menu_title
    section_text {
      ...AlignedMarkdown
    }
    image {
      ...CommonMediaField
      staticFile {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
        }
      }
    }
    accordion_item {
      title
      text
    }
  }
`;
